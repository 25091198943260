<div class="container-fluid bg-primary-light py-2">
  <div class="row">
    <div class="col-6">

    </div>

    <div class="col-6 text-end">

      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
          {{ authService.currentUser?.getFullName() }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button 
            ngbDropdownItem 
            class="d-flex align-items-center text-danger"
            (click)="authService.signOut()">
            <i class="material-icons me-1">logout</i>
            Cerrar sesión
          </button>
        </div>
      </div>

    </div>
  </div>
</div>